import Manager from "./Manager";
import titleBack from "../../assets/images/titleBack.svg";
import axios from "axios";
import {EndPoints} from "../../config/EndPoints";
import {useState, useEffect} from "react";
import ButtonLoader from "../common/ButtonLoader";
import {useSelector} from "react-redux";
import {LivingRoomIcon} from "../../assets/icons";
import cls from "./home.module.css";
import DropDown from "../common/DropDown";
import DescriptiveDropDown from "../common/DescriptiveDropDown";
import { MagicStars } from "../../assets/icons";
import PageLoader from "../common/PageLoader";

export default function Home(){
    const { isMobileView }                                          = useSelector(state => state.project);
    const [loadingGeneratorBtn, setLoadingGeneratorBtn]             = useState(false);
    const [showErrorMsg, setShowErrorMsg]                           = useState(false);
    const [showErrorMsg2, setShowErrorMsg2]                           = useState(false);
    const [isLoading, setIsLoading]                           = useState(true);
    const [filters, setFilters]                                     = useState({});
    const [selectedRoomType, setSelectedRoomType]                   = useState(1);
    const [selectedStyle, setSelectedStyle]                         = useState();
    const [selectedColor, setSelectedColor]                         = useState();
    const [selectedBudget, setSelectedBudget]                       = useState();
    const [layouts, setLayouts]                                     = useState([]);
    const [moodBoards, setMoodBoards]                               = useState([]);

    const { budget, color, room_type, style } = filters;
    const filteredStyle = style?.filter(i => i.room_id === selectedRoomType);
    const filteredColor = color?.filter(e => e.styles_filter_id === selectedStyle);
    const filteredBudget = budget?.filter(e => e.styles_filter_id === selectedStyle);
    const [isArabic, setIsArabic] = useState(true);

    useEffect(() => {
        // Check if 'ar' is present in the URL
        if (window.location.href.includes("/ar")) {
            setIsArabic(true);
        }else{
            setIsArabic(false);
        }
       
     }, []);


    // const handleStyleChange = (e) => {
    //     setStyle(e.target.value);
    // }
//     const generateArtwork = () => {
//         setShowErrorMsg(false);
//         setLoadingGeneratorBtn(true);

//         console.log(selectedColor);
//         console.log(selectedBudget);
// if(selectedColor){

// }else{
//     setIsLoading(true);
// }
//         axios.get(`${EndPoints.generateArtwork}?color_id=${selectedColor?selectedColor:34}&budget_id=${selectedBudget?selectedBudget:1}`)
//             .then(res => {
//                 setLoadingGeneratorBtn(false);
//                 const { layout, moodboards } = res.data;
//                 setLayouts(layout);
//                 setMoodBoards(moodboards);
//                 setIsLoading(false);
//             })
//             .catch(err => {
//                 console.log(err)
//                 setLoadingGeneratorBtn(false);
//                 setShowErrorMsg(true);
//                 setIsLoading(false);
//             })
//     }
// Retrieve the counter from localStorage, or initialize it to 0 if it doesn't exist
let generateArtworkCounter = parseInt(localStorage.getItem('generateArtworkCounter')) || 0;



const contiusgenerateArtwork = () => {

    if (selectedColor) {
        // Your logic here
    } else {
        setIsLoading(true);
    }

    axios.get(`${EndPoints.generateArtwork}?color_id=${selectedColor ? selectedColor : 34}&budget_id=${selectedBudget ? selectedBudget : 1}`)
        .then(res => {
            setLoadingGeneratorBtn(false);
            const { layout, moodboards } = res.data;
            setLayouts(layout);
            setMoodBoards(moodboards);
            setIsLoading(false);
        })
        .catch(err => {
            console.log(err);
            setLoadingGeneratorBtn(false);
            setShowErrorMsg(true);
            setIsLoading(false);
        });

    // Increment the counter
    generateArtworkCounter++;
    
    // Save the updated counter to localStorage
    localStorage.setItem('generateArtworkCounter', generateArtworkCounter);

}
const generateArtwork = () => {

    setShowErrorMsg(false);
    setLoadingGeneratorBtn(true);

    console.log( 'generateArtworkCounter', generateArtworkCounter);
    console.log(selectedColor);
    console.log(selectedBudget);

    const islogggedin = window.userStatus ;
    console.log('islogggedin' , islogggedin);
    // Check if the counter has reached 5
    if (generateArtworkCounter >= 5  && islogggedin?.logged_in == "no") {
        setIsLoading(false);
        setLoadingGeneratorBtn(false);
      
        if (selectedColor ) {
            // Your logic here
            // alert("The function has been triggered 5 times!");
            setShowErrorMsg2(true)
            document.querySelector('.wd-header-my-account').click();

        }
        // generateArtworkCounter = 0; // Reset the counter if needed
        // localStorage.setItem('generateArtworkCounter', generateArtworkCounter); // Reset in localStorage as well
    }else{
        contiusgenerateArtwork()
    }
};

    const getMoodBoardFilters = () => {
        axios.get(EndPoints.moodBoardFilters)
            .then(res => {
                setIsLoading(false);
                setFilters(res.data.filters);
                console.log(res.data.filters);
              setTimeout(() => {
                generateArtwork();
              }, 1);
            })
            .catch(err => {
                console.log(err)
            })
    };

    useEffect(() => {
        getMoodBoardFilters();
    }, []);

    useEffect(() => {
        setSelectedStyle(filteredStyle?.length && filteredStyle[0].id);
    }, [selectedRoomType, filters]);

    useEffect(() => {
        if(filteredColor?.length){
            setSelectedColor(filteredColor[0].id);
        }
        if(filteredBudget?.length){
            setSelectedBudget(filteredBudget[0].id);
        }
    }, [selectedStyle]);

    return (
        <>
            {isLoading && <div className="flex justify-center items-center h-screen">
                <PageLoader text={isArabic ? ".... برجاء الانتظار " : "Setting up the application..."} />
            </div>}
            {!isLoading && <div className={`grid grid-cols-1 sm:grid-cols-2 gap-4 ${isMobileView ? 'p-1' : 'p-5'}`}>
                <div>
                    <div className="flex justify-center items-center flex-col" style={{paddingTop: isMobileView ? '5px' : '135px'}}>
                        <div className="flex items-center gap-2 p-1" style={{maxWidth: isMobileView ? '360px' : '483px', overflowX: 'auto'}}>
                            {room_type?.map((room) => <div
                                className={`${cls.room_types_grid} ${room.id === selectedRoomType ? cls.room_types_selected_grid : ''} p-3 cursor-pointer flex justify-center items-center flex-col`}
                                onClick={() => setSelectedRoomType(room.id)}
                            >
                                <div>
                                    <LivingRoomIcon fill={room.id === selectedRoomType ? 'white' : 'black'} />
                                </div>
                                <div className="whitespace-nowrap" >
                                    {room.name}
                                </div>
                            </div>)}
                        </div>
                        <div className="mt-5" style={{width: isMobileView ? '360px' : '483px'}}>
                            {filteredStyle?.length > 0 && <div>
                                <DescriptiveDropDown options={filteredStyle} selected={selectedStyle} setSelected={setSelectedStyle}/>
                            </div>}
                            {filteredColor?.length > 0 && <div className="mt-1">
                                <DescriptiveDropDown options={filteredColor} selected={selectedColor} setSelected={setSelectedColor}/>
                            </div>}
                            {filteredBudget?.length > 0 && <div className="mt-1">
                                <DropDown options={filteredBudget} selected={selectedBudget} setSelected={setSelectedBudget}/>
                            </div>}
                        </div>
                        <div className="flex items-center flex-col mt-5 w-9/12">
                            <button className={`${cls.generate_btn_style} flex items-center justify-center gap-3 w-full py-2`}
                            onClick={generateArtwork}
                            >
                                {loadingGeneratorBtn ? <ButtonLoader text={isArabic ? "....جاري التنفيذ " : "Generating..."} /> : isArabic?  "اصنع التصميم " : "Generate Magic"}
                                <MagicStars />
                            </button>
                            {showErrorMsg && <span style={{color: 'red'}}>{isArabic ? "حدث خطأ ما! يرجى المحاولة مرة أخرى."  :"Something went wrong! Please try again"}</span>}
                            {showErrorMsg2 && <span style={{color: 'red'}}>{isArabic ? "لقد تخطيت الحد الاقصي من المحاولات يجب تسجيل الدخول لكي تتمكن من عمل محاولات اخري "  :"You have exceeded the maximum number of attempts. You must log in to make more attempts."






}</span>}
                        </div>
                    </div>
                </div>

                <Manager
                    layouts={layouts}
                    moodBoards={moodBoards}
                />
            </div>}
        </>
    )
}
